<script>
    import NavBarMenu from '@/components/layout/NavBarMenu.vue';
    import BurgerBtn from '@/components/ui/BurgerBtn.vue';
    import ControlsBackAndHome from '@/components/layout/ControlsBackAndHome.vue';
    import HeaderComponent from '@/components/layout/HeaderComponent.vue';

    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { NavBarMenu, BurgerBtn, ControlsBackAndHome, HeaderComponent },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        mounted() {
            sdkclaro.getInstance().switchGoBackButton(true)
            this.$store.dispatch('checkMembership')
        }
    }
</script>

<template>
    <div class="home-view app-view pt-17">
        <header-component burgerBtn>
            <h2 class="tx-24 tx-bold tx-center mb-0">
                {{ currentRouteName }}
            </h2>
        </header-component>

        <router-view class="pt-8"/>

        <!-- NAV BAR MENU -->
        <transition name="nav-bar">
            <nav-bar-menu v-show="$store.getters['navBarMenuOpen']"/> 
        </transition>
    </div>
</template>
<style lang="scss" scoped>
    .router-link-active{
        background-color: black;
        color: #fff;
    }
</style>