<script>
    export default {
        methods: {
            goToBack(){ 
                if (this.$route.name === 'Detalle de producto') {
                    this.trackLog({ 
                        action: 'Cancelar compra de producto', 
                        medio: 'Botón', 
                        sectionId: 3, 
                        elementId: 999, 
                    })
                }
                history.back() 
            }
        },
        mounted(){

        }
    }
</script>
<template>
    <div class="flex">
        <div class="h-10 w-10 flex align-center justify-center relative" @click="goToBack()">
            <img class="absolute w-3" src="@/assets/images/layout/icon-angle-left.svg" alt="">
        </div>
        <router-link class="h-10 w-10 flex align-center justify-center relative" to="/inicio/arena-zuggy">
            <img class="absolute w-5" src="@/assets/images/layout/icon-home.svg" alt="">
        </router-link>
    </div>
</template>