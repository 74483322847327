<script>
    export default {
        props: {
            getAvatar: String
        },
        data: () => ({
            previewImage: null
        }), 
        computed: {
            avatar() {
                if (this.previewImage != null) return this.previewImage
                return this.getAvatar === null ? require('@/assets/images/views/create_profile/avatar-default.png') : this.getAvatar
            }
        },  
        methods:{
            uploadImage(e){
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.previewImage = e.target.result;
                    this.$emit('input', this.previewImage)
                };
            }
        }
    } 
</script>
<template>
    <div class="relative mx-auto h-24 w-24">
        <div class="h-24 w-24 rounded-100pr bg-center bg-cover bg-no-repeat" :style="{ 'background-image': 'url(' + avatar + ')' }"></div>
        <label style="z-index: 100;" class="absolute b-0 r-0" for="inputUpload">
            <img src="@/assets/images/views/create_profile/icon-edit.png" alt="">
            <input id="inputUpload" type="file" accept="image/jpeg" @change="uploadImage" hidden>
        </label>
    </div>
 </template>