<script>
    export default {
        props: {
            question: String,
            answer: String
        },
        data: () => ({
            dropdown: false
        }),
        methods: {
            toggleDropdown(){
                this.dropdown = !this.dropdown;
            },
            beforeEnter(el){
                el.style.height = 0
            },
            enter(el){
                el.style.height = el.scrollHeight + 'px'
            },
            beforeLeave(el){
                el.style.height = el.scrollHeight + 'px'
            },
            leave(el){
                el.style.height = 0
            },
        },
    }
</script>

<template>
    <div class="dropdown">
        <div class="dropdown-header relative tx-18" @click="toggleDropdown()">
            {{ question }}

            <img src="@/assets/images/layout/angle-box.svg" alt="" class="dropdown-icon" :class="[ dropdown && 'active' ]">

            <img class="decore-shine absolute l-0 r-0 b-0" src="@/assets/images/layout/shine-btn.png" alt="">
        </div>

        <transition
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave">
            <div class="dropdown-wrapper" v-if="dropdown" v-html="answer"></div>
        </transition>
    </div>
</template>
<style lang="scss" scoped>
    .dropdown {
        margin-bottom: 12px;
    }
    .dropdown:last-child {
        margin-bottom: 0;
    }
    .dropdown-header {
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0 20px;
    }
    .dropdown-icon {
        max-width: 24px;
        transition: transform 200ms ease;
    }
    .dropdown-icon.active {
        transform: rotate(180deg);
    }
    .dropdown-wrapper {
        overflow: hidden;
        padding: 0 20px;
        transition: height 400ms ease;
        transform: translateY(8px);
    }
</style>