<script>
    import axios from 'axios';
    import MyButton from '@/components/ui/MyButton.vue';
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { MyButton },
        data: () => ({
            current: {},
            fielType: '',
        }),     
        methods: {
            async getNew (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'terminos');
                    this.current = res.data
                } catch (err) { console.log(err) }
            },
            goToBack(){ history.back() }
        },
        mounted() {
            this.getNew();
            this.trackLog({ 
                action: 'Ingreso a terminos', 
                medio: 'Compra membresia', 
                sectionId: 2, 
                elementId: this.$route.params.id, 
            })
            sdkclaro.getInstance().switchGoBackButton(true)
        }
    }
</script>

<template>
    <div class="new-detail-view app-view">

        <h2 class="tx-24 tx-bold tx-center mb-0 pt-5">
            Términos y condiciones
        </h2>
        
        <div class="container pb-5">
            <!--<p class="tx-20 tx-bold">Términos y Condiciones</p>-->
            <!-- BODY -->
            <div class="tx-14 tx-medium mb-6 mt-10" v-html="current.descripcion"></div>
            <br>
            <my-button label="Regresar" action="Regresar" @clicked="goToBack()"/>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .decore-shine{ max-width: initial;}
</style>
