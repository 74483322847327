<script>
    import axios from 'axios';

    import HeaderComponent from '@/components/layout/HeaderComponent.vue';
    import NavBarMenu from '@/components/layout/NavBarMenu.vue';

    export default {
        components: { HeaderComponent, NavBarMenu },
        data: () => ({
            current: {},
        }),     
        methods: {
            async getReward (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'detalle-beneficios-zuggy?id_beneficio=' + this.$route.params.id);
                    this.current = res.data
                } catch (err) { console.log(err) }
            },
        },
        mounted() {
            this.getReward();

            this.trackLog({ 
                action: 'Ingreso a detalle de recompensa', 
                medio: 'Banner', 
                sectionId: 9, 
                elementId: this.$route.params.id, 
            })
        }
    }
</script>

<template>
    <div class="reward-detail-view pt-28">
        <header-component burgerBtn>
            <h2 class="tx-24 tx-bold tx-center mb-0">
                Recompensa
            </h2>
        </header-component>

        <div class="container">
            <div class="relative bg-center bg-cover bg-no-repeat h-55 w-100pr rounded-10 border-1 border-white mb-5" :style="{ 'background-image': 'url(' + current.imagen + ')' }">
                <img class="decore-shine absolute l-0 r-0 b-0 -mb-2 mx-auto" src="@/assets/images/layout/shine-banner.png" alt="">
            </div>

            <p class="tx-20 tx-bold" v-html="current.titulo"></p>

            <!-- BODY -->
            <div class="tx-14 tx-medium mb-6" v-html="current.descripcion"></div>
        </div>

        <!-- NAV BAR MENU -->
        <transition name="nav-bar">
            <nav-bar-menu v-show="$store.getters['navBarMenuOpen']"/> 
        </transition>
    </div>
</template>