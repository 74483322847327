<script>
    import axios from "axios"

    import MyButton from '@/components/ui/MyButton.vue'
    import ControlsBackAndHome from '@/components/layout/ControlsBackAndHome.vue'
    import DropDown from '@/components/ui/DropDown.vue'
    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import ModalIke from '@/components/ModalIke.vue'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { MyButton, ControlsBackAndHome, DropDown, HeaderComponent, ModalIke },
        data: () => ({
            modalForm: false,
            questions: [],
            ikeMembership: null
        }),
        methods: {
            async getQuestions() {
                try {
                    const res = await axios.get(process.env.VUE_APP_SERVICE_URL + 'preguntas-frecuentes')
                    this.questions = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err); }
            },
            async ikeValidation() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + `ike-vigencia?usuario_id=${this.$store.getters['userId']}`)
                    this.ikeMembership = res.data.error
                    console.log(res.data);
                } catch (err) { console.log(err); }
            },
            handleModalForm(){
                this.ikeValidation()
                console.log("ikeValidation " + this.ikeMembership)
                if (this.ikeMembership === 1) { 
                    this.modalForm = !this.modalForm 
                    return
                }

                sdkclaro.getInstance().getTopic("EXECUTE_Minimal_View", {
                    typeOperation: "openUrl",
                    parameters: "tel:+5590633558",
                });

                //window.open('tel:+525636374542')   
            },
            mailTo(){
                //document.location.href = "mailto:soporte@zuggyclubgamers.com";  
                sdkclaro.getInstance().getTopic("EXECUTE_Minimal_View", {
                    typeOperation: "openUrl",
                    parameters: "mailto:soporte@zuggyclubgamers.com",
                }); 
            },
            AvisodeprivacidadTo(){
                document.location.download = "mailto:soporte@zuggyclubgamers.com";   
            },
            termsTo(path){
                this.$router.push({ path: path })   
            },
            avisoTo(path){
                this.$router.push({ path: path })   
            },
            politicaTo(path){
                this.$router.push({ path: path })   
            },
            forosTo(path){
                this.$router.push({ path: path })   
            },
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true)
            this.getQuestions()
            this.ikeValidation()
            this.trackLog({ 
                action: 'Ingreso a asistencia', 
                medio: 'Botón', 
                sectionId: 4, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="help-view app-view pt-28">
        <header-component>
            <h2 class="tx-24 tx-bold tx-center mb-0">
                Asistencia
            </h2>
        </header-component>

        <div class="container">
            <div class="relative">
                <my-button class="mb-4" label="Asistencia Zuggy Club Gamers" @clicked="mailTo()"/>
                <my-button class="mb-4" label="Soporte Técnico" :sectionId="4" action="Solicitar soporte técnico" @clicked="handleModalForm()"/>
                <my-button class="mb-4" label="Términos y Condiciones" action="" @clicked="termsTo(`/inicio/terminos/`)"/>
                <my-button class="mb-4" label="Aviso de privacidad" action="" @clicked="avisoTo(`/inicio/aviso/`)"/>
                <my-button class="mb-4" label="Política de reembolso de códigos digitales" action="" @clicked="politicaTo(`/inicio/politicas/`)"/>
                <my-button class="mb-4" label="Políticas de foros" action="" @clicked="forosTo(`/inicio/foros/`)"/>
                
                <!--
                <a class="btn btn--cg-1 mb-4 relative" download href="./pdfs/aviso.pdf"> 
                    Aviso de privacidad
                    <img class="decore-shine absolute l-0 r-0 b-0" src="@/assets/images/layout/shine-btn.png" alt="">
                </a>
                <a class="btn btn--cg-1 mb-4 relative" download href="@/assets/pdfs/faqs.pdf"> 
                    FAQs
                    <img class="decore-shine absolute l-0 r-0 b-0" src="@/assets/images/layout/shine-btn.png" alt="">
                </a>
                <a class="btn btn--cg-1 mb-4 relative" download href="./pdfs/reembolsos.pdf"> 
                    Política de reembolso de códigos digitales
                    <img class="decore-shine absolute l-0 r-0 b-0" src="@/assets/images/layout/shine-btn.png" alt="">
                </a>
                <a class="btn btn--cg-1 mb-10 relative" download href="./pdfs/foros.pdf"> 
                    Políticas de foros
                    <img class="decore-shine absolute l-0 r-0 b-0" src="@/assets/images/layout/shine-btn.png" alt="">
                </a>-->
                <!-- LAYER RESTRICT -->
                <layer-restrict v-if="!$store.getters['isMember']"/>
            </div>

            <p class="tx-22 tx-center">
                Preguntas frecuentes
            </p>

            <drop-down class="mb-5" :question="item.pregunta" :answer="item.respuesta" v-for="(item, idx) in questions" :key="idx"/>
        </div>

        <transition name="fade">
            <modal-ike v-if="modalForm" @close="handleModalForm()"></modal-ike>
        </transition>
    </div>
</template>