<script>
    import axios from "axios"
    import LoadingScreen from './components/layout/LoadingScreen.vue';
    import RestrictModal from './components/RestrictModal.vue';
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { LoadingScreen, RestrictModal },
        data: () => ({ isLoading: true }),
        mounted() { 
            this.$store.dispatch('checkMembership')
            this.$store.dispatch('checkProfile')

            setTimeout(() => this.isLoading = false, 400) 
            //console.log(process.env.VUE_APP_SERVICE_URL + this.$store.getters['userId'])
            //console.log(this.$store.state.userId)
        },
        beforeCreate(){
            this.$store.commit('setClaroPayId');
        },
    }
</script>
<template>
    <div class="club-gamer-app shadow-3" id="app">

<!--         <transition name="fade">
            <loading-screen v-show="isLoading"/>  
        </transition>     
        <transition name="slide-fade">
            <router-view v-show="!isLoading"/>
        </transition> -->

        <router-view/>

        <!-- RESTRICT MODAL -->
        <transition name="fade">
            <restrict-modal v-if="$store.getters['restrictModalState']"/>
        </transition>
    </div>
</template>