<script>
    import axios from 'axios';
    import MyButton from '@/components/ui/MyButton.vue'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { MyButton },
        data: () => ({
            userProfile: {},
            swiper: {
                spaceBetween: 120,
                allowTouchMove: true,
                slidesPerView: 2.5,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
            },
            swiperData: [
                {
                    id: 1, 
                    desc: 'Torneos y <br> experiencias <br> a nivel nacional',
                    icon: 'world' 
                },
                {
                    id: 2, 
                    desc: 'Promociones <br> y descuentos <br> exclusivos',
                    icon:'tag' 
                },
                {
                    id: 3, 
                    desc: 'Complementos <br> de tus videojuegos <br> favoritos',
                    icon:'game' 
                },
                {
                    id: 4, 
                    desc: 'Puntos Zuggy',
                    icon:'zuggy' 
                }
            ]
        }),
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true);
            this.trackLog({ 
                action: 'Ingreso registro completado', 
                medio: 'Botón', 
                sectionId: 8, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="introduction-view app-view pt-15">
        <div class="container">
            <h1 class="tx-24 tx-center mb-0">
                ¡Ya eres miembro <br> de nuestro Zuggy Club Gamers!
            </h1>
            <img class="decore-shine mb-12" src="@/assets/images/layout/shine-1.png" alt="">
            
            <div class="tx-18 tx-center mb-10">
                Ahora podrás disfrutar de:
            </div>
        </div>

        <!-- SWIPER -->
        <swiper class="swiper tx-18 tx-bold tx-center w-100pr mb-20 mt-17" :options="swiper" ref="swiper">
            <swiper-slide class="flex justify-center" v-for="(item, idx) in swiperData" :key="idx">
                <div class="w-fit">
                    <div class="wrapper-icon grid place-items-center h-45 w-45 mb-5">
                        <img :src="require(`@/assets/images/views/welcome/icon-${item.icon}.png`)" alt="">
                    </div>
                    <p class="tx-18 tx-bold tx-center mb-0" v-html="item.desc">
                    </p>
                </div>
            </swiper-slide>
        </swiper>

        <div class="container">
            <my-button class="mb-4" label="¡Comienza tu experiencia y crea tu perfil!" path="/crear-perfil" action="Ingreso a mi perfil" :sectionId="8" link/>
            <my-button action="Ingreso a arena zuggy" :sectionId="8" label="Navegar" path="/inicio/arena-zuggy" link/>
        </div>
    </div>
</template>