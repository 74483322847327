<script>
    export default {

    }
</script>
<template>
    <div class="modal absolute flex align-center z-10">
        <div class="modal-lightbox lightbox-ike w-100pr p-4 bg-top bg-cover bg-no-repeat rounded-10" :style="{'background-image' : `url(${require('@/assets/images/layout/bg-modal-alert.png')})`}">
            <div class="modal-close tx-white tx-52 mx-auto -mb-17" @click="$emit('close')">
                &times;
            </div>
            <img class="absolute t-0 l-0 r-0 mx-auto -mt-20" src="@/assets/images/layout/icon-alert.svg" alt="">
            <slot></slot>
        </div>
    </div> 
</template>
<style lang="scss" scoped>
    .modal-close{
        top: initial;
        left: 0;
        bottom: 0;
    }
    .lightbox-ike{
        background-color: rgba($color: #000000, $alpha: 0);
    }
</style>