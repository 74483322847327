<script>
    import axios from 'axios';
    
    import ModalVideoPlayer from '@/components/ModalVideoPlayer.vue';
    import MyButton from '@/components/ui/MyButton.vue';
    import * as sdkclaro from "@claro/sdkclaro";
    
    export default {
        components: { ModalVideoPlayer, MyButton },

        data: () => ({
            modal: true,
            showVideo: '',
            swiper: {
                spaceBetween: 120,
                allowTouchMove: true,
                slidesPerView: 2.5,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
            },
            swiperData: [
                {
                    id: 1, 
                    desc: 'Torneos y <br> experiencias <br> a nivel nacional <br> e internacional',
                    icon:'world' 
                },
                {
                    id: 2, 
                    desc: 'Promociones <br> y descuentos <br> exclusivos',
                    icon:'tag' 
                },
                {
                    id: 3, 
                    desc: 'Complementos <br> de tus videojuegos <br> favoritos',
                    icon:'game' 
                },
                {
                    id: 4, 
                    desc: 'Puntos Zuggy',
                    icon:'zuggy' 
                }
            ]
        }),
        methods: {
            async validateProfileClaroPay(){
                console.log('validateProfileClaroPay welcome')

                if(this.$store.getters['isMember']){
                    this.$router.replace({ path: '/inicio/arena-zuggy' })
                }
            },
            changeSlide(index) {
                this.$refs.swiper.$swiper.slideTo(index, 400);
            },
            nextSlide() {
                this.$refs.swiper.$swiper.slideNext();
            },
            prevSlide() {
                this.$refs.swiper.$swiper.slidePrev();
            },
            closeModal() {
                this.modal = false;
                this.trackLog({ 
                    action: 'Video visto', 
                    medio: 'Botón', 
                    sectionId: 1, 
                    elementId: null, 
                })
            },
            async getShowVideo (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'welcome-video?usuario_id=' + localStorage.getItem('claro-pay-id'));
                    this.current = res.data

                    if(this.current.video == 1){
                        this.showVideo = true
                    } else {
                        this.showVideo = false
                    }

                } catch (err) { console.log(err) }
            },
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true);
            this.validateProfileClaroPay();
            this.getShowVideo();

            this.trackLog({ 
                action: 'Ingreso a bienvenida', 
                medio: 'Botón', 
                sectionId: 8, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="welcome-view app-view pt-15">
        <div class="container">
            <h1 class="tx-24 tx-bold tx-center mb-0">
                ¡Bienvenido <br> a tu Zuggy Club Gamers!
            </h1>
            <img class="decore-shine mb-13" src="@/assets/images/layout/shine-1.png" alt="">
            <br>
            <my-button class="mb-4" label="OBTÉN TU MEMBRESÍA" action="Comprar Membresía" :sectionId="8" path="/comprar-membresia" link v-if="!$store.getters['isMember']"/>
            <!--<my-button :class="{'d-none no-events': !$store.getters['isMember']}" label="Navegar" action="Ingreso a arena zuggy" :sectionId="8" path="/inicio/arena-zuggy" link/>-->
            <br>
            <p class="tx-18 tx-center">
                Con tu membresía mensual podrás <br> obtener miles de beneficios como:
            </p>
        </div>

        

        <!-- SWIPER -->
        <swiper class="swiper tx-18 tx-bold tx-center w-100pr mb-20 mt-17" :options="swiper" ref="swiper">
            <swiper-slide class="flex justify-center" v-for="(item, idx) in swiperData" :key="idx">
                <div class="w-fit">
                    <div class="wrapper-icon grid place-items-center h-45 w-45 mb-5">
                        <img :src="require(`@/assets/images/views/welcome/icon-${item.icon}.png`)" alt="">
                    </div>
                    <p class="tx-18 tx-bold tx-center mb-0" v-html="item.desc">
                    </p>
                </div>
            </swiper-slide>
        </swiper>


        <!-- MODAL VIDEO -->
        <transition name="fade" v-if="this.showVideo">
            
            <modal-video-player @close="closeModal()" v-if="modal"/>
        </transition>
    </div>
</template>

<style scoped>
    .d-none {
        display: none;
    }

</style>