<script>
    import MyButton from './ui/MyButton.vue';
    
    export default {
        components: { MyButton },
        props: {
            data: Object
        },
        data: () => ({
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }),
        methods :{
            handleClick(path){
                this.$router.push({ path: path });
            }
        },
        computed: {
            date() {
                const date = new Date(this.data.fecha_compra)
                return date.getDate() + ' de ' + this.months[date.getMonth()] 
            }
        }
    }
</script>
<template>
    <div class="shopping-history-item relative p-3 overflow-hidden z-0">
        <div class="flex justify-between mb-3">
            <p class="w-75pr tx-medium m-0" v-html="data.titulo"></p>
            <p class="tx-medium tx-whitespace-nowrap mb-0" v-html="date"></p>
        </div>
        <span class="tx-36 tx-medium opacity-03">
            {{data.nombre}}
        </span>

        <!-- BTN -->
        <my-button classBtn="btn--cg-sm ml-auto absolute b-0 r-0 m-1" label="Comprar" @clicked="handleClick(`/producto/${data.producto_id}`)" :sectionId="7" action="Click en producto comprado"/>

        <!-- ICON CATEGORY -->
<!--         <div class="h-60 w-60 opacity-04 absolute t-0 r-0 -mt-2 -mr-2 -z-1" :style="{'background-image': `url(${require('@/assets/images/views/create_profile/test-icon-category.png')})`}"></div> -->
    </div>
</template> 
<style lang="scss" scoped>
    .shopping-history-item{
        background-color: rgba($color: #000000, $alpha: 0.3);
    }

</style>