<script>
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true)
        }
    }
</script>
<template>
    <div class="pay-method-view t-view">
        Agregar un metodo de pago

        <div class="flex justify-center my-10">
            <div class="form-radio flex align-center mr-10">
                <input class="form-radio-input" type="radio" name="radio" id="tdc">
                <label class="form-radio-label t-box t-white" for="tdc">
                    TDC
                </label>
            </div>
            <div class="form-radio flex align-center">
                <input class="form-radio-input" type="radio" name="radio" id="paypal">
                <label class="form-radio-label t-box t-white" for="paypal">
                    Paypal
                </label>
            </div>   
        </div>

        <h2>
            Informacion de pago
        </h2>

        <div class="form-group">
            <input class="form-input" type="text" placeholder="Nombre del titular">
        </div>

        <div class="form-group">
            <input class="form-input" type="text" placeholder="Numero de tarjeta">
        </div>

        <div class="form-grid mb-10">
            <div class="form-group col-8">
                <input class="form-input" type="text" placeholder="Mes">
            </div>
            <div class="form-group col-8">
                <input class="form-input" type="text" placeholder="Año">
            </div>
            <div class="form-group col-8">
                <input class="form-input" type="text" placeholder="CVV">
            </div>
        </div>

        <router-link to="/registro/completado" class="t-box t-green mb-10">
            Contrata membresia 3 meses + 1 mes gratis Tu club gamer
        </router-link>
    </div>
</template>