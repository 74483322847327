<script>
    import axios from 'axios'

    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import NavBarMenu from '@/components/layout/NavBarMenu.vue'
    import * as sdkclaro from "@claro/sdkclaro";
    
    export default {
        components: { HeaderComponent, NavBarMenu },
        data: () => ({
            subcategories: [],
        }),
        methods: {
            async getSubcategories (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'subcategorias?id=' + this.$route.params.id);
                    this.subcategories = res.data;
                } catch (err) { console.log(err) }
            },
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true)
            this.getSubcategories();

            this.trackLog({ 
                action: 'Ingreso a subcategorías', 
                medio: 'Botón', 
                sectionId: 3, 
                elementId: this.$route.params.id, 
            })
        }
    }
</script>

<template>
    <div class="store-category-view app-view">
        <header-component class="py-4" burgerBtn>
            <h2 class="tx-24 tx-bold tx-center mx-auto mb-0">
                {{ $route.params.name }}
            </h2>
        </header-component>

        <div class="container mt-30">
            <p class="tx-24 tx-medium tx-center mb-8">
                Selecciona una categoría
            </p>
            <ul class="tx-18 bullet-none p-0">
                <router-link class="flex justify-between border-b-1 py-5" v-for="(item, idx) in subcategories" :key="idx" :to="`/categoria/${item.categoria_id}/${item.nombre}/${item.id}`">
                    {{ item.nombre }}
                    <img class="rotate-180" src="@/assets/images/layout/icon-angle-left.svg" alt="">
                </router-link>
            </ul>
        </div>

        <!-- NAV BAR MENU -->
        <transition name="nav-bar">
            <nav-bar-menu v-show="$store.getters['navBarMenuOpen']"/> 
        </transition>
    </div>
</template>