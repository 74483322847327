<script>
    export default {
        methods: {
            handleBtn() {
                this.$store.commit('toggleNavBarMenu')
                this.trackLog({ 
                    action: 'Abrir menu principal', 
                    medio: 'Botón', 
                    sectionId: null, 
                    elementId: null, 
                })
            }
        }
    }
</script>
<template>
    <button class="burger trigger-menu" @click="handleBtn()">
        <div class="burger-inner">
            <span class="burger-inner-line bg-white"></span>
            <span class="burger-inner-line bg-white"></span>
            <span class="burger-inner-line bg-white"></span>
        </div>
    </button>
</template>