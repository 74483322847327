<script>
    import axios from 'axios'

    import NavBarMenu from '@/components/layout/NavBarMenu.vue'
    import ProductItem from '@/components/ProductItem.vue'
    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { NavBarMenu, ProductItem, HeaderComponent },
        data: () => ({
            headerHeight: 0,
            dataProducts: [
                { id: 1, title: 'xbox 6 meses', description: 'Compra 6 meses de Xbox Ultimate precios especial.', price: 699, promotion: 'Con tu compra ganas XXXX puntos', category: 'plataformas', platform: 'xbox', date: '20 de junio' },
                { id: 2, title: 'playstation 2 mese', description: 'Adquiere 2 meses de PlayStation a un precio especial.', price: 450, promotion: 'Con tu compra ganas XXXX puntos', category: 'plataformas', platform: 'playstation', date: '20 de junio' },
                { id: 3, title: 'nintendo 3 meses', description: 'Compra 3 meses de Nintendo Online a un súper precio.', price: 599, promotion: 'Con tu compra ganas XXXX puntos', category: 'plataformas', platform: 'nintendo', date: '20 de junio' },
                { id: 4, title: 'play store 2 meses', description: 'Compra tu membresía de 2 meses en Play Store', price: 250, promotion: 'Con tu compra ganas XXXX puntos', category: 'plataformas', platform: 'playStore', date: '20 de junio' },
            ]
        }), 
        computed: {
            getCategoryName(){
                return this.$route.params.name
            },
        },
        methods: {
            async getProducts (){
                try{
                    const checkSubcategory = this.$route.params.subcategoryId == 0 ? '&sub_categoria_id=' : '&subcategoria_id='

                    const res = await axios.post(
                        process.env.VUE_APP_SERVICE_URL + 'productos-tienda?id=' + this.$route.params.categoryId + checkSubcategory + this.$route.params.subcategoryId
                    );
                    
                    this.dataProducts = res.data
                } catch (err) { console.log(err) }
            },
        },
        mounted() {

            sdkclaro.getInstance().switchGoBackButton(true)
            
            const header = document.querySelector('.main-header');
            this.headerHeight = (header.offsetHeight + 24) + 'px';

            this.getProducts();

            this.trackLog({ 
                action: 'Ingreso a categoría', 
                medio: 'Botón', 
                sectionId: 3, 
                elementId: this.$route.params.categoryId, 
            })
        }
    }
</script>
<template>
    <div class="store-category-view app-view">
        <header-component class="py-4" burgerBtn>
            <h2 class="tx-24 tx-bold tx-center mx-auto mb-0">
                {{ getCategoryName }}
            </h2>
        </header-component>

        <div class="container" :style="{'margin-top': headerHeight}">
            <product-item class="mb-9" v-for="(item, idx) in dataProducts" :key="idx" :data="item"/>
        </div>

        <!-- NAV BAR MENU -->
        <transition name="nav-bar">
            <nav-bar-menu v-show="$store.getters['navBarMenuOpen']"/> 
        </transition>
    </div>
</template>