<script>
    import ControlsBackAndHome from '@/components/layout/ControlsBackAndHome.vue'
    import MyButton from '@/components/ui/MyButton.vue'

    export default {
        components: { ControlsBackAndHome, MyButton },
        data: () => ({
        }),
        methods: {
            toggleModalShare(){
                this.modalShare = !this.modalShare
            },
            toggleModalSend(){
                this.modalSend = !this.modalSend
            },
            toggleModalWin(){
                this.modalWin = !this.modalWin
            },
            goToClaroApp(){
                window.open('https://play.google.com/store/apps/details?id=com.globalhitss.claro.pay&hl=es_MX&gl=US&pli=1', '_blank');
            }
        }
    }
</script>
<template>
    <div class="pools-view app-view pt-15">
        <header class="container relative flex justify-between align-center mb-20">
            <controls-back-and-home class="-ml-4 z-1"/>

            <div class="absolute -mb-5 ">
                <h2 class="tx-24 tx-bold tx-center mb-0">
                    Quiniela
                </h2>
                <img class="decore-shine" src="@/assets/images/layout/shine-1.png" alt="">
            </div>
        </header>   
        <div class="container">
            <p class="tx-18 tx-medium tx-center mb-18">
                Participa en la Quiniela Fase Final <br> Apertura 2023
            </p>
            <p class="tx-18 tx-medium tx-center mb-11">
                ¡Comparte con tus amigos, juega <br> y obtén la oportunidad <br> de ganar increíbles premios!
            </p>
            <div class="relative w-fit mx-auto mb-11">
                <img src="@/assets/images/views/icon-gift.png" alt="">
                <img class="decore-shine absolute b-0 l-0 r-0 mx-auto -mb-1" src="@/assets/images/layout/shine-box.png" alt="">
            </div>
            <my-button class="mb-4" label="Recompensas"/>
            <my-button label="Invitar amigos" />
        </div>
    </div>  
</template>