<script>
    import MyButton from './ui/MyButton.vue'

    export default {
        components: { MyButton },
        computed: {
            data() {
                return this.$store.getters['restrictModal']
            },
            path(){ 
                return this.data.type === 1 ? '/comprar-membresia' : '/crear-perfil'
            } 
        },
        methods: {
            handleClose() {
                this.$store.commit('toggleRestrictModal')
            },
            handleBtn(){
                this.$router.push({ path: this.path }) 
                this.$store.commit('toggleRestrictModal')
            }
        } 
    }
</script>
<template>
    <div class="modal absolute flex align-center z-10">
        <div class="modal-lightbox lightbox-ike w-100pr py-6 px-3 bg-center bg-cover bg-no-repeat rounded-10" :style="{'background-image' : `url(${require('@/assets/images/layout/bg-modal-restrict.png')})`}">
            <div class="modal-close tx-white tx-52 mx-auto -mb-17" @click="handleClose()">
                &times;
            </div>

            <p class="tx-18 tx-center mt-5" v-html="data.text"></p>
    
            <my-button :label="data.btnLabel" @clicked="handleBtn()"/>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .modal-close{
        top: initial;
        left: 0;
        bottom: 0;
    }
</style>