<script>
    import axios from 'axios';

    export default {
        data: () => ({
            current: {},
            fielType: '',
        }),     
        methods: {
            async getNew (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'aviso');
                    this.current = res.data
                } catch (err) { console.log(err) }
            },
        },
        mounted() {
            this.getNew();

            this.trackLog({ 
                action: 'Ingreso a terminos', 
                medio: 'Banner', 
                sectionId: 2, 
                elementId: this.$route.params.id, 
            })
        }
    }
</script>

<template>
    <div class="new-detail-view">
        <div class="container">

            <!--<p class="tx-20 tx-bold">Términos y Condiciones</p>-->
            <!-- BODY -->
            <div class="tx-14 tx-medium mb-6 mt-10" v-html="current.descripcion"></div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .decore-shine{ max-width: initial;}
</style>
