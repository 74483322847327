<script>
    import axios from "axios"

    export default {
        data: () => ({
            menuOptions: [
                { title: 'Zona Premier', icon: 'ground', path: '/inicio/arena-zuggy' },
                { title: 'Tienda', icon: 'shop', path: '/inicio/tienda' },
                { title: 'Comunidad', icon: 'community', path: '/inicio/comunidad' },
                { title: 'Recompensas', icon: 'rewards', path: '/recompensas' },
                { title: 'Asistencia', icon: 'help', path: '/asistencia' },
            ],
        }),
        methods: {
            changeRoute(path) {
                this.$store.commit('toggleNavBarMenu');
                this.$router.push({ path: path });
            },
            closeMenu(){
                this.$store.commit('toggleNavBarMenu')
                this.trackLog({ 
                    action: 'Cerrar menu principal', 
                    medio: 'Botón', 
                    sectionId: null, 
                    elementId: null, 
                })
            }
        }
    }
</script>
<template>
    <div class="tx-14 tx-medium tx-purple nav-bar-menu w-100pr h-100vh absolute t-0 l-0 z-100">
        <div class="nav-bar-menu-inner w-50pr flex flex-column justify-between bg-white ml-auto h-100pr py-30 transition-200">

            <!-- MENU -->
            <div class="px-6">
                <div class="h-10 w-10 grid place-items-center ml-auto -mr-3 mb-10" @click="closeMenu()">
                    <img src="@/assets/images/layout/icon-times-menu.svg" alt="">
                </div>

                <nav>
                    <ul class="bullet-none p-0 m-0 w-100pr">
                        <li class="flex align-center w-100pr mb-5" @click="changeRoute(opt.path)" v-for="(opt, idx) in menuOptions" :key="idx">
                            <div class="h-7 w-7 grid place-items-center flex-none mr-4">
                                <img :src="require(`@/assets/images/layout/icon-menu/icon-${opt.icon}.svg`)" alt="">
                            </div>
                            {{ opt.title }}
                        </li>
                    </ul>
                </nav>
            </div>

            <!-- PROFILE -->
            <div class="mx-5 border-t-1 border-purple pt-7" v-if="$store.getters['isMember']">
                <div class="flex align-center w-100pr mb-5" @click="changeRoute($store.getters['profileCompleted'] ? '/mi-perfil' : '/crear-perfil')">
                    <img class="mr-4 ml-1" style="width: 28px; height: 28px;" src="@/assets/images/layout/icon-menu/perfil.png" alt="">
                    Perfil
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .nav-bar-menu{
        background-color: rgba($color: #000000, $alpha: 0.3);

        &-inner{
            box-shadow: 0 -19px 20px rgba($color: #000000, $alpha: 0.16);
            width: 56%;
        }
    }
</style>