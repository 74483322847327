<script>
    export default {

    }
</script>
<template>
    <div class="chat-view t-view t-yellow">
        <div class="flex justify-between align-center mb-10">
            <strong>
                Club Gamer
            </strong>
            <div class="flex justify-end tx-bold gap-5">
                <router-link to="/registro/completado">
                    Regresar
                </router-link>
                <router-link to="/inicio/arena-zuggy">
                    Inicio
                </router-link>
            </div>
        </div>
        <h2 class="t-white">
            En linea
        </h2>
        <ul class="bullet-none p-0 mb-10">
            <li class="h-5 w-5 rounded-100pr t-green mb-2"></li>
            <li class="h-5 w-5 rounded-100pr t-green mb-2"></li>
            <li class="h-5 w-5 rounded-100pr t-green mb-2"></li>
            <li class="h-5 w-5 rounded-100pr t-green mb-2"></li>
        </ul>

        <h2 class="t-white">
            Desconectados
        </h2>
        <ul class="bullet-none p-0 mb-10">
            <li class="h-5 w-5 rounded-100pr t-orange mb-2"></li>
            <li class="h-5 w-5 rounded-100pr t-orange mb-2"></li>
            <li class="h-5 w-5 rounded-100pr t-orange mb-2"></li>
            <li class="h-5 w-5 rounded-100pr t-orange mb-2"></li>
        </ul>

        <h2 class="t-white">
            Sugerencias (Dependiendo de tu perfil)
        </h2>
        <ul class="bullet-none p-0 mb-10">
            <li class="h-5 w-5 rounded-100pr t-red mb-2"></li>
        </ul>
    </div>
</template>