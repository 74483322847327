<script>
    import axios from 'axios'

    import MyButton from './ui/MyButton.vue'
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, minLength, maxLength } from '@vuelidate/validators'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { MyButton },
        setup () {
            return { v$: useVuelidate() }
        },
        data: () => ({
            userName: '',
            userEmail: '',
            userPhoneNumber: '',
            loading: false,
            modal: {
                state: false,
                description: '',
                type : ''
            },
        }),
        validations: () => ({
            userName: { required },
            userEmail: { required, email },
            userPhoneNumber: { required, minLengthValue: minLength(10), maxLengthValue: maxLength(10)},
        }),
        methods: {
            async submitForm(){
                const result = await this.v$.$validate()
                console.log("IKE")
                console.log(result)
                if (!result) return

                this.loading = true;

                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'ike', this.user);
                    this.loading = false;
                    this.modal = {
                        state: true,
                        description: '¡Muchas gracias! <br><br> Datos guardados con éxito, activado soporte tecnico.',
                        type: 'success',
                    }
                    console.log("Instancia")
                    sdkclaro.getInstance().getTopic("EXECUTE_Minimal_View", {
                        typeOperation: "openUrl",
                        parameters: "tel: 5590633558",
                    });

                    console.log(res);
                    } catch (err) { 
                        this.loading = false;
                        this.modal = {
                            state: true,
                            description: 'Ha ocurrido un error <br><br> favor de intentarlo de nuevo',
                            type: 'error',
                        }
                    console.log(err); 
                }
            },
            handleAccept(){
                this.$emit('close')
                this.modal.state = false
            },
        },
        computed: {
            user () {
                return {
                    "usuario_id"  : this.$store.getters['userId'],
                    "nombre"      : this.userName,
                    "email"       : this.userEmail,
                    "telefono"    : this.userPhoneNumber,
                }
            },
        }
    }
</script>

<template>
    <div class="modal absolute flex align-center z-10">
        <div class="modal-lightbox lightbox-ike w-100pr p-4 bg-center bg-cover bg-no-repeat rounded-10" :style="{'background-image' : `url(${require('@/assets/images/layout/bg-modal-alert.png')})`}">
            <div class="modal-close tx-white tx-52 mx-auto -mb-17" @click="$emit('close')">
                &times;
            </div>
            <img class="absolute t-0 l-0 r-0 mx-auto -mt-20" src="@/assets/images/layout/icon-alert.svg" alt="">

            <p class="tx-24 tx-bold tx-center mt-5">
                Antes de continuar
            </p>
            <p class="tx-center tx-medium">
                Necesitamos validar algunos datos <br> para conectarte con <br> nuestro servicio. 
            </p>
            
            <!-- FORM -->
            <!-- NAME -->
            <div class="form-group mb-7">
                <input class="form-input form-input--cg" type="text" placeholder="Nombre completo" maxlength="50" v-model.trim="userName" pattern="[a-z]{1,50}">
            </div>
            <div style="color: red;" class="tx-14 opacity-08 tx-center mb-4 -mt-5" v-if="v$.userName.$error">
                Favor de escribir tu nombre completo sin caracteres especiales
            </div>

            <!-- EMAIL -->
            <div class="form-group mb-7">
                <input class="form-input form-input--cg" type="text" placeholder="Email" maxlength="50" v-model.trim="userEmail" pattern="[a-z]{1,50}">
            </div>
            <div style="color: red;" class="tx-14 opacity-08 tx-center mb-4 -mt-5" v-if="v$.userEmail.$error">
                Favor de escribir un correo electrónico válido
            </div>

            <!-- PHONE NUMBER -->
            <div class="form-group mb-7">
                <input class="form-input form-input--cg" type="number" placeholder="Teléfono celular" pattern="[0-9]{1,10}" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10" v-model.trim="userPhoneNumber">
            </div>
            <div style="color: red;" class="tx-14 opacity-08 tx-center mb-4 -mt-5" v-if="v$.userPhoneNumber.$error">
                Favor de escribir un número de teléfono válido
            </div>

            <my-button classBtn="btn--success" label="Confirmar datos" @clicked="submitForm()"/>
        </div>

        <!-- LOADER -->
        <div class="overlay fixed grid place-items-center t-0 l-0 w-100pr h-100pr z-10" v-if="loading">
            <div class="loader-spinner"></div>
        </div>

        <!-- MODAL ALERTS -->
        <transition name="fade">
            <div class="modal absolute flex align-center z-20" v-if="modal.state">
                <div class="modal-lightbox w-100pr py-10 px-4 bg-center bg-cover bg-no-repeat rounded-0">
                    <div class="tx-black">
                        <p class="tx-18 tx-center mb-9" v-html="modal.description"></p>
                        <button class="btn mx-auto flex" :class="modal.type === 'success' ? 'btn--success' : 'btn--danger'" @click="handleAccept()">
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<style lang="scss" scoped>
    .modal-close{
        top: initial;
        left: 0;
        bottom: 0;
    }
    .lightbox-ike{
        background-color: rgba($color: #000000, $alpha: 0);
    }
    .form-input{
        border-radius: 10px;
        height: 47px;
        background-color: rgba($color: #fff, $alpha: 0.35);

        &:focus {
            color: #fff;
        }
    }

    .overlay{
        background: rgba($color: #fff, $alpha: 0.4)
    }

</style>