<script>
    export default {
        props: {
            label: String,
            link: Boolean,
            path: String,
            classBtn:  String,

            action: String,
            sectionId: Number,
            elementId: Number,
        },
        methods: {
            clicked(){
                this.$emit('clicked');
                this.trackLog({ 
                    action: this.action, 
                    medio: 'Botón', 
                    sectionId: this.sectionId, 
                    elementId: this.elementId != undefined ? this.elementId : null, 
                })

                if (this.link)  this.$router.push({ path: this.path });
               
            }
        }
    }
</script>
<template>
    <div class="relative" :class="this.classBtn">
        <button class="btn btn--cg-1 tx-bold" :class="this.classBtn" v-html="label" @click="clicked()"/>

        <!-- DECORE -->
        <img class="decore-shine absolute l-0 r-0 b-0" src="@/assets/images/layout/shine-btn.png" alt="">
    </div>
</template>
<style scoped>
    .decore-shine{ margin-bottom: -2px; }
</style>